import React from 'react';
import { Card, CardContent, CardActions, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const JobCard = ({ jobs }) => {
  return (
    <div style={{display:"flex",gap:"2em", flexDirection:"column", width: "100%"}}>
      {jobs.map((job, index) => (
        <Card key={index}>
          <CardContent>
            apply more jobs related to {job.role} 
          </CardContent>
          <CardActions className="applyBtn">
            <Link to={`/jobs`}>
              <Button size="small" variant="contained" className="bgbtn" color="primary">
                apply now
              </Button>
            </Link>
          </CardActions>
        </Card>
      ))}
    </div>
  );
};

export default JobCard;
